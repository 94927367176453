import { client } from '../client';

import { BankingDetails, PayLinkStatus, PayLinkTicket, StripeIntent } from './types';
import { CURRENCY } from '../../helpers/currency';
import { Providers } from '../../config/providers';

export * from './types';

export const getBankingDetails = async (slug: string): Promise<BankingDetails> => {
  const { data } = await client.get(`banking/pay-link/${slug}/`);

  return data;
};

export const setPayLinkStatus = async (slug: string, status: PayLinkStatus): Promise<void> => {
  await client.patch(`banking/pay-link/${slug}/`, {
    status,
  });
};

export const createPayLinkTicket = async (params: { id: number; lang: string }): Promise<PayLinkTicket> => {
  const { data } = await client.post<PayLinkTicket>('banking/pay-link/ticket/', {
    pay_link_id: params.id,
    lang: params.lang,
  });

  return data;
};

export const createStripeIntent = async (params: {
  amount: number;
  currency: CURRENCY & string;
  provider?: Providers;
}): Promise<StripeIntent> => {
  const { provider = Providers.None, ...queryParams } = params;

  const { data } = await client.post<StripeIntent>('/banking/stripe/intent/', {
    ...queryParams,
    service: provider,
  });

  return data;
};
