import { client } from '../client';

import {
  AlfredDepartmentsParams,
  CitiesParams,
  City,
  Delivers,
  DeliversParams,
  Department,
  Pricing,
  ProvidersMap,
  Services,
  TrackingInfo,
} from './types';

import { countryMapper } from './adatapter/country-mapper';

import { getLangCode } from '../../helpers/i18n';

export * from './types';

export const getTracking = async (barcode: string): Promise<TrackingInfo[]> => {
  const { data } = await client.get<TrackingInfo[]>('/services/tracking/', {
    params: {
      tracking_number: barcode,
      lang: getLangCode(),
    },
  });

  return data.map(countryMapper);
};

export const getPricing = (params: {
  country: string;
  destination_country: string;
  weight: string;
  value?: number;
  extensions?: number[];
}) =>
  client.get<Pricing>('/services/prices/', {
    params,
  });

export const getPostCodeCourier = async (postCode: string): Promise<boolean> => {
  try {
    const {
      data: { courier },
    } = await client.get<{ courier: boolean }>(`/services/postcodes/${postCode}/`);

    return courier;
    // eslint-disable-next-line no-empty
  } catch (e) {}

  return false;
};

export const getRussianCities = async (): Promise<City[]> => {
  try {
    const { data } = await client.get<City[]>(`/services/rus-cities/`);

    return (
      (data as City[])
        .map((city) => {
          const { region } = (city as never) as Record<string, string>;

          return {
            ...city,
            display_name: city.display_name || city.name,
            region: {
              id: 0,
              name: region,
              display_name: region,
              name_ascii: region,
            },
          };
        })
        // eslint-disable-next-line no-nested-ternary
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    );
    // eslint-disable-next-line no-empty
  } catch (e) {}

  return [];
};

export const getDetailedPrice = async (params: {
  origin_service?: string;
  destination_service?: string;
  origin_type?: string;
  deliver_type?: string;
  weight?: number;
  template?: string;
  width?: number;
  height?: number;
  length?: number;
  max?: string;
  no_docs?: boolean;
}): Promise<Pricing> => {
  const { data } = await client.get<Pricing>('/services/price/', {
    params,
  });

  return data;
};

export const getDelivers = async (params: DeliversParams, isDiscountDisabled = false): Promise<Delivers[]> => {
  const { data } = await client.get<(Delivers & { discount: number })[]>('/services/delivers/', {
    params,
  });

  return data.map(({ discount = null, ...delivers }) => {
    // map discount
    Object.keys(delivers.services).forEach((service) => {
      // @ts-ignore
      delivers.services[service].discount = isDiscountDisabled ? null : discount || null;
    });

    // sort services
    const sorted = Object.entries(delivers.services).sort(([, { order: a = 0 }], [, { order: b = 0 }]) => a - b);

    // re-write to delivers object
    delivers.services = sorted.reduce((acc, [key, cur]) => {
      acc[key as keyof Services] = cur;

      return acc;
    }, {} as Services);

    return delivers;
  });
};

export const getCities = async (params: CitiesParams): Promise<City[]> => {
  if (params.country === 'ru') {
    return getRussianCities();
  }

  const { data } = await client.get<City[]>('/services/cities/', {
    params,
  });

  return data;
};

export const getAlfredDepartments = async (params: AlfredDepartmentsParams): Promise<Department[]> => {
  const { data } = await client.get<Department[]>('/alfred/departments/', {
    params,
  });

  return data;
};

export const getEnabledProviders = async (): Promise<ProvidersMap> => {
  const { data } = await client.get<ProvidersMap>('services/country-delivers/');

  return data;
};
