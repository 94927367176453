import { Providers, providerToRouteSlug } from '../../config/providers';
import { PaymentBody, PiraeusTicketBody } from './types';

import { client } from '../client';

export * from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createPayments = async (provider: Providers, body: PaymentBody): Promise<any> => {
  const { data } = await client.post(`/${providerToRouteSlug(provider)}/payments/`, body);

  return data;
};

export const createPiraeusTicket = async (body: PiraeusTicketBody): Promise<{ response: string }> => {
  const { data } = await client.post(`/banking/piraeus/ticket/`, body);

  return data;
};
