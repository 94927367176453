import { client } from '../client';
import { ShipmentsBody } from './types';
import { Shipment } from '../client.types';
import { Providers, providerToRouteSlug } from '../../config/providers';

export * from './types';

export const createShipment = async (provider: Providers, body: ShipmentsBody): Promise<Shipment> => {
  const { data } = await client.post(`/${providerToRouteSlug(provider)}/shipments/`, body);

  return data;
};
