export enum PayLinkStatus {
  // handled on the backend
  pending = 'pending',
  opened = 'opened',
  paid = 'paid',
  // handled on the backend
  expired = 'expired',
  canceled = 'canceled',
  failed = 'failed',
}

export interface BankingDetails {
  id: number;
  merchant: number;
  payment_type: string;
  bar_code: string | null;
  custom_code: string;
  value: number;
  currency: string;
  lang: string;
  description: string;
  payer_first_name: string;
  payer_last_name: string;
  payer_phone: string;
  payer_email: string | null;
  status: PayLinkStatus;
  value_eur: number;
  expire_date: string;
}

export interface PayLinkTicket {
  price: string;
  response: string;
}

export interface StripeIntent {
  intent_id: string;
  client_secret: string;
}
