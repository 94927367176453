import { PaymentEvents } from '../config/analytics';

export const GA_TRACKING_ID = 'GTM-NQM9FH9';

export function gtag(...params: NotTyped[]) {
  const w = window as NotTyped;

  if (w.gtag) {
    w.gtag(...params);
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  gtag('config', GA_TRACKING_ID, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: NotTyped) => {
  gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
  });
};

export const paymentEvent = (source: PaymentEvents): void => {
  // eslint-disable-next-line no-console
  console.log('analytics [paymentEvent]', source);

  event(source);
};
