import { client } from '../client';

import { Extension } from '../../entities/rates';

import { CURRENCY } from '../../helpers/currency';

export const getExtensions = async (): Promise<Extension[]> => {
  const { data } = await client.get<Extension[]>('/extensions/');

  return data;
};

export type ExtensionPrice = Record<
  'price' | 'localPrice' | 'localDeclarationPrice' | 'localMax' | 'maxValue',
  string
> & {
  isHigher: boolean;
};

export const getExtensionsPrice = async (params: {
  value: number;
  service: string;
  currency: CURRENCY;
}): Promise<ExtensionPrice> => {
  const {
    data: {
      price,
      currency_price: localPrice,
      currency_value: localDeclarationPrice,
      currency_max_value: localMax,
      max_value: maxValue,
    },
  } = await client.get<Record<string, number>>('/extensions/calculate/', {
    params: {
      ...params,
      currency: params.currency.toLowerCase(),
    },
  });

  const cur = params.currency.toUpperCase();

  return {
    price: `${price} EUR`,
    localPrice: `${localPrice} ${cur}`,
    localDeclarationPrice: `${localDeclarationPrice} ${cur}`,
    localMax: `${localMax} ${cur}`,
    maxValue: `${maxValue} EUR`,
    isHigher: localDeclarationPrice >= localMax,
  };
};
