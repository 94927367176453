import { Message } from '../components/element';

export const success = (message: string): void => {
  Message({
    message,
    type: 'success',
    iconClass: 'el-icon-success',
    customClass: 'el-message--success',
  });
};

export const warning = (message: string): void => {
  Message({
    message,
    type: 'warning',
    iconClass: 'el-icon-warning',
    customClass: 'el-message--warning',
  });
};

export const error = (message: string): void => {
  Message({
    message,
    type: 'error',
    iconClass: 'el-icon-error',
    customClass: 'el-message--error',
  });
};
