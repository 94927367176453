import { ProvidersMap, TrackingInfo } from '../types';
import { Providers } from '../../../config/providers';

const badList: Record<string, string[]> = {
  pl: ['польша'],
};

export function countryMapper(tr: TrackingInfo): TrackingInfo {
  const country = String(tr.current_country).toLowerCase();

  // eslint-disable-next-line no-restricted-syntax
  for (const [code, list] of Object.entries(badList)) {
    if (list.includes(country)) {
      return {
        ...tr,

        current_country: code,
      };
    }
  }

  return tr;
}

export const filterInboundProviders = (fromCountryCode: string, map: ProvidersMap): Providers[] => {
  if (fromCountryCode in map) {
    return map[fromCountryCode] || [];
  }

  return [];
};
